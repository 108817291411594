<template>
<div class="main-container">
  <el-breadcrumb separator="/" class="breadcrumb" style="margin:0.6rem;display: inline-block;">
    <el-breadcrumb-item style="">资产盘点</el-breadcrumb-item>
    <el-breadcrumb-item>{{$route.query.note_id ? '编辑' : '新增'}}</el-breadcrumb-item>
  </el-breadcrumb>
  <el-form ref="form" :rules="rules" :model="form" label-width="130px" class="content-block">
  <div class="form-block ">
    <div class="form-title">
      <img class="descriptions-icon" src="@/assets/img/titleIcon.png">
      <p>基本信息</p>
    </div>
    <el-row>
      <el-col :span="6">
        <el-form-item label="盘点单编号">
          <el-input disabled   size="small" v-model="form.note_no"></el-input>
        </el-form-item>
      </el-col>
      <el-col  :span="6">
        <el-form-item label="盘点单名称"  prop="title">
          <el-input size="small" required v-model="form.title"></el-input>
        </el-form-item>
      </el-col>
      <el-col  :span="12">
        <el-form-item label="盘点时间" prop="check_time">
          <el-date-picker
              required
              :picker-options="pickerOptions"
              v-model="form.check_time"
              type="daterange"
              value-format="yyyy-MM-dd"
              :default-time="['00:00:00', '23:59:59']"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"

          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="备注">
      <el-input type="textarea"  maxlength="200" show-word-limit :autosize="{ minRows: 2, maxRows: 4}" v-model="form.remarks"></el-input>
    </el-form-item>
  </div>
  <div class="form-block">
      <div class="form-title">
        <img class="descriptions-icon" src="@/assets/img/titleIcon.png">
        <p >盘点范围</p>
        <span class="remark-span">(注：不选择盘点范围，默认为全部资产)</span>
      </div>
          <el-form-item label="所属平台">
            <el-select
                class="check_select"
                v-model="form.platform_ids"
                placeholder="请选择平台"
                size="small"
                multiple
                filterable
                clearable
                @change="platChange"
            >
              <el-option
                  v-for="item in optionsProvince"
                  :key="item.platform"
                  :label="item.platform_unique"
                  :value="item.platform"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属公司">
            <el-select
                @change="changeCompany"
                class="check_select"
                v-model="form.company_ids"
                placeholder="请输入所属公司"
                loading-text="正在从服务端获取数据"
                size="small"
                filterable
                multiple
                clearable
                remote
                :remote-method="remoteMethod"
            >
              <el-option
                  v-for="item in organizationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资产状态">
            <el-select  class="check_select" v-model="form.asset_statuses"  multiple size="small" placeholder="请选择">
              <el-option
                  v-for="item in assetStatusOptions"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val">
              </el-option>
            </el-select>
          </el-form-item>
    <div class="page-footer-btn">
      <el-button size="small" class="dtl-btn" @click="submit(1)">提交</el-button>
      <el-button size="small" class="dtl-btn"  v-if="!$route.query.note_id" @click="submit(0)">保存</el-button>
      <el-button size="small" @click="goBack()">取消</el-button>
    </div>
    </div>
  </el-form>
</div>
</template>
<script>
import {getInventoryDtl, saveInventory, tempInventory, updateInventory} from "@/api/assetCheck";
import PlatSelect from "@/components/PlatSelect.vue";
import {organization_list} from "@/api/search";
import ProvinceSelect from "@/components/ProvinceSelect.vue";
import options from "@/views/AssetManagement/mixin/options";
import api from "@/api/tongji";
export default {
  name: "acEdit",
  mixins: [options,],
  components: {ProvinceSelect, PlatSelect},
  data(){
    return{
      optionsProvince: [],
      form:{
        check_time: [],
        note_no: '', //盘点单编号
        title: '',  // 盘点名称
        start_time: '',
        end_time: '',  // 盘点时间
        remarks: '', // 盘点备注
        platform_ids: [],  // 所属平台
        company_ids: [], // 所属公司
        company_names: [], // 所属公司
        asset_statuses: [], // 资产状态
      },
      organization_id: '',
      organizationList: [],
      optionsCity: [],
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() < new Date().setHours(0, 0, 0, 0);
        }
      },
      rules: {
        title: [
          { required: true, message: '请输入盘点名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        check_time:[
          { required: true, message: '请选择盘点时间', trigger: 'change' },
        ]
      }
    }
  },
  created() {
    this.getNo()
    this.platformFun()
    if(this.$route.query.note_id){
      this.getDtl()
    }else{
      this.getTempInfo()
    }
  },
  methods:{
    getTempInfo(){ //获取暂存数据
      tempInventory().then(res=>{
        if(res.data.note_no){
          this.form = Object.assign(this.form,res.data)
          if(this.form.company_names){
            this.form.company_names.forEach(item =>{
              item.label = item.organization_name
              item.value = item.organization_id
            })
            this.organizationList = this.form.company_names
          }
          if(this.form.platform_ids){
            this.form.platform_ids = this.form.platform_ids.split(',')  // 所属平台
          }
          if( this.form.company_ids){
            this.form.company_ids = this.form.company_ids.split(',')  // 所属平台
            this.form.company_ids = this.form.company_ids.reduce((acc, item) => {
              acc.push(Number(item));
              return acc;
            }, []);
          }
          if(this.form.asset_statuses){
            this.form.asset_statuses = this.form.asset_statuses.split(',')  // 所属平台
            this.form.asset_statuses = this.form.asset_statuses.reduce((ac, item) => {
              ac.push(Number(item));
              return ac;
            }, []);
          }
          this.$set(this.form.check_time,0,this.form.start_time)
          this.$set(this.form.check_time,1,this.form.end_time)
        }
      })
    },
    getDtl(){ //获取详情数据
      getInventoryDtl({note_id: this.$route.query.note_id}).then(res => {
        this.form = Object.assign(this.form,res.data)
        this.$set(this.form.check_time,0,this.form.start_time)
        this.$set(this.form.check_time,1,this.form.end_time)
        this.organizationList= this.form.company_names
        if(this.form.company_names){
          this.form.company_names =  this.form.company_names.forEach(item =>{
            item.label = item.organization_name
            item.value = item.organization_id
            delete item.organization_name
            delete item.organization_id
          })
        }
        if(this.form.platform_ids){
          this.form.platform_ids = this.form.platform_ids.split(',')  // 所属平台
        }
        if( this.form.company_ids){
          this.form.company_ids = this.form.company_ids.split(',')  // 所属平台
          this.form.company_ids = this.form.company_ids.reduce((acc, item) => {
            acc.push(Number(item));
            return acc;
          }, []);
        }
        if(this.form.asset_statuses){
          this.form.asset_statuses = this.form.asset_statuses.split(',')  // 所属平台
          this.form.asset_statuses = this.form.asset_statuses.reduce((ac, item) => {
            ac.push(Number(item));
            return ac;
          }, []);
        }
      })
    },
    platformFun() {
      api.platform({}).then((res) => {
        this.optionsProvince = res.data.data
      })
    },
    platChange(val){  // 选择平台
      this.form.platform_ids = val
    },
    remoteMethod(e) {
      this.organizationList = organization_list({ organization_name: e })
    },
    changeCompany(val){
      this.company_ids = val
    },
    submit(type){
      if(!this.form.title){
       return  this.$message.error('请填写盘点单名称！')
      }else if(!this.form.check_time || this.form.check_time.length < 1 ){
        return  this.$message.error('请选择盘点时间！')
      }
      const loading = this.$loading({
        lock: true,
        text: type ? '盘点单数据正在提交中，请稍后...' :'保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if(this.form.check_time[0].length < 11){
        this.form.start_time = this.form.check_time[0]+' '+"00:00:00"
        this.form.end_time = this.form.check_time[1]+' '+"23:59:59"
      }else{
        this.form.start_time = this.form.check_time[0]
        this.form.end_time = this.form.check_time[1]
      }

      // delete this.form.check_times
      // delete this.form.company_names
      if(this.form.asset_statuses && this.form.asset_statuses.length < 1){
        delete this.form.asset_statuses
      }
      if(this.form.company_ids && this.form.company_ids.length < 1){
        delete this.form.company_ids
        // delete this.form.company_names
      }else{
      }
      if( this.form.platform_ids && this.form.platform_ids.length<1){
        delete this.form.platform_ids
      }
      if(!this.form.is_delete){
        delete this.form.is_delete
      }
      this.form.is_submitted = type
      if(this.$route.query.note_id){
        updateInventory(this.form).then(res=>{
          this.$router.push({path:'/assetCheck/list'})
          loading.close()
        }).catch(err=>{
          loading.close()
        })
      }else{
        saveInventory(this.form).then(res=>{
          this.$router.push({path:'/assetCheck/list'})
          loading.close()
        }).catch(err=>{
          loading.close()
        })
      }
    },
    getNo(){
      let checkId = 'PD' + Date.now().toString()
      this.form.note_no = checkId
    },
    goBack(){
      this.$router.push({ path: '/assetCheck/list' })
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/asset';
.form-block {
  .form-title{
    span{
      font-size: px(24);
    }
    .remark-span{
      font-size: px(18);
      color:#BD3124;
      margin-left: 10px;
    }
    p{
      display: inline-block;
      margin-left: 10px;
      margin-bottom:10px;
    }
  }
}
.page-footer-btn{
  ::v-deep.el-button{
    padding:8px 40px;
    margin: 40px;

  }
}
.city-select{
  display: inline-block;
  margin-left: 14px;
}
.check_select{
  width: 60%;
}
::v-deep .check_select.el-select .el-input .el-input__inner{
  width: 100%!important;
}
</style>